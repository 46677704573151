import { CaptureConsole, ExtraErrorData as ExtraErrorDataIntegration } from "@sentry/integrations"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import { JS_CONF } from "@/constants"

const serverErrorsRegex = new RegExp("401 Unauthorized", "mi")

export const initSentry = () => {
    Sentry.init({
        dsn: JS_CONF.sentry_dsn_js,
        environment: JS_CONF.sentry_env,
        attachStacktrace: true,
        normalizeDepth: 10,
        integrations: [
            new BrowserTracing(),
            new ExtraErrorDataIntegration(),
            new CaptureConsole({ levels: ["error"] })
        ],
        ignoreErrors: [serverErrorsRegex]
    });
    Sentry.setTag("release_version", JS_CONF.version);
}
